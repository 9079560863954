import React from 'react';
import './App.css';

function App() {
    const year = new Date().getFullYear();
    return (
        <div className="App">
            <header className="App-header">
                <div className="box-boarder">
                    <img src={"./anne.jpeg"} className="App-logo" alt="Anne Malone portrait"/>
                    <h3>
                        AMAL Secretarial Services
                    </h3>
                    <p className="centered-paragraph">
                        Anne Malone is a seasoned professional with over 15 years of experience providing secretarial
                        services and general administration consulting in the legal field.
                        <br/>
                        <br/>
                        Working predominantly with barristers, Anne prioritizes privacy and maintains strict
                        confidentiality in her work. Reach out directly to amalsecservices@gmail.com with any queries.
                    </p>
                </div>
            </header>
            <footer className="footer">
                &copy; {year} AMAL Secretarial Services
            </footer>
        </div>
    );
}

export default App;
